import Footer from '@/components/layout/footer';
import Header from '@/components/layout/header';
import Head from 'next/head';

export default function FourOhFour({ companyGeneralData }) {
    companyGeneralData = JSON.parse(companyGeneralData);

    return <>

        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content="Revenda de veículos multimarcas, novos e semi-novos. Veículos com procedência e garantia." />
            <meta name="author" content="https://bndv.com.br" />
            <title>{`${companyGeneralData.company.nickName} - Erro 404`}</title>
        </Head>

        <div className="main-wrapper">
            <Header company={companyGeneralData.company} />
            <section className="h3-about-section pt-5 pb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="at-section-title text-center">
                                <h1>Página não encontrada</h1>
                                <p className="mb-0">A página que você está tentando acessar não foi encontrada, volte a Home e tente acessar novamente o conteúdo desejado.</p>
                                <a href="/">Voltar para Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer company={companyGeneralData.company} />
        </div>

    </>
}